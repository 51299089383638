/** @jsx jsx */
import { Global, jsx } from "@emotion/core"
import { globalHistory, Router } from "@reach/router"
import { Component } from "react"
import { HEADLINE_DOWNLOAD_LINK } from "../constants"
import { fetchSitesForTag, IThumbnails } from "../fetchSites"
import { fetchTags, TagData } from "../fetchTags"
import { globalStyles } from "../styles/globalStyles"
import { normalize } from "../styles/normalizeStyles"
import { sendPageView } from "../utilities/analytics"
import Hamburger from "./Hamburger"
import Nav from "./Nav"
import Page from "./Page"

interface ISitesDataForPages {
  [key: string]: {
    urls: string[]
    thumbnails: IThumbnails
  }[]
}

interface ILoadingDataForPages {
  [key: string]: boolean
}

interface IAllLoaded {
  [key: string]: boolean
}

interface IState {
  tags: TagData[]
  navIsOpen: boolean
  sitesData: ISitesDataForPages
  loadingStates: ILoadingDataForPages
  allLoaded: IAllLoaded
}

export default class App extends Component {
  state: IState = {
    tags: [],
    navIsOpen: false,
    sitesData: {},
    loadingStates: {},
    allLoaded: {},
  }

  componentDidMount() {
    fetchTags()
      .then((res) => res.json())
      .then((tags) => {
        this.setState({ tags: tags })
      })
      .catch((error) => console.log(error))
    this.setupAnalytics()
    // close nav on page change
    globalHistory.listen(() => {
      this.setState({ navIsOpen: false })
    })
  }

  setupAnalytics() {
    sendPageView()
    globalHistory.listen(() => {
      sendPageView()
    })
  }

  handlePageLoadFor(tag: string): () => void {
    const { sitesData, loadingStates, allLoaded } = this.state
    return () => {
      if (!sitesData[tag]) {
        this.setState({
          loadingStates: {
            ...loadingStates,
            [tag]: true,
          },
        })
        fetchSitesForTag(tag, 0, 8).then((data) => {
          this.setState({
            sitesData: {
              ...sitesData,
              [tag]: data.results,
            },
            allLoaded: {
              ...allLoaded,
              [tag]: data.metadata.count + data.metadata.offset === data.metadata.total,
            },
            loadingStates: {
              ...loadingStates,
              [tag]: false,
            },
          })
        })
      }
    }
  }

  loadNextPageFor(tag: string): () => void {
    const { sitesData, loadingStates, allLoaded } = this.state
    return () => {
      if (!loadingStates[tag] && !allLoaded[tag]) {
        this.setState({
          loadingStates: {
            ...loadingStates,
            [tag]: true,
          },
        })
        fetchSitesForTag(tag, sitesData[tag].length, 8).then((data) => {
          this.setState({
            sitesData: {
              ...sitesData,
              [tag]: [...sitesData[tag], ...data.results],
            },
            allLoaded: {
              ...allLoaded,
              [tag]: data.metadata.count + data.metadata.offset === data.metadata.total,
            },
            loadingStates: {
              ...loadingStates,
              [tag]: false,
            },
          })
        })
      }
    }
  }

  render() {
    const { navIsOpen, tags, sitesData, loadingStates, allLoaded } = this.state
    return (
      <div>
        <Global styles={[normalize, globalStyles]} />
        <Hamburger
          navIsOpen={navIsOpen}
          onClick={() => {
            this.setState({
              navIsOpen: !this.state.navIsOpen,
            })
          }}
        />
        <Nav tags={tags} navIsOpen={navIsOpen} />
        <Router>
          {tags.length > 0 && (
            <Page
              loading={loadingStates["featured"]}
              allLoaded={allLoaded["featured"]}
              sitesData={sitesData["featured"] || []}
              path="/"
              default
              dataTitle={"featured"}
              loadNextPage={this.loadNextPageFor("featured")}
              handlePageLoad={this.handlePageLoadFor("featured")}
              title={"Welcome to Universe"}
              description={
                <span>
                  Explore a new generation of websites&mdash;made with a phone.{" "}
                  <a href={HEADLINE_DOWNLOAD_LINK}>Download</a> and create your own site for free.
                </span>
              }
            />
          )}
          {tags.length > 0 && (
            <Page
              loading={loadingStates["all"]}
              allLoaded={allLoaded["all"]}
              sitesData={sitesData["all"] || []}
              path="/all"
              dataTitle={"all"}
              loadNextPage={this.loadNextPageFor("all")}
              handlePageLoad={this.handlePageLoadFor("all")}
              title={"All Sites"}
              noIndex={true}
            />
          )}
          {tags.map((tag) => {
            return (
              <Page
                loading={loadingStates[tag.title]}
                allLoaded={allLoaded[tag.title]}
                sitesData={sitesData[tag.title] || []}
                key={tag.title}
                dataTitle={tag.title}
                loadNextPage={this.loadNextPageFor(tag.title)}
                handlePageLoad={this.handlePageLoadFor(tag.title)}
                title={tag.displayTitle}
                description={tag.description}
                path={`/${tag.title === "buyBlock" ? ":path(commerce|buyBlock)" : tag.title}`}
              />
            )
          })}
        </Router>
      </div>
    )
  }
}
