import ReactGA from "react-ga"
import { MouseEvent } from "react"

function _shouldSendEvents() {
  return process.env.REACT_APP_BUILD_CONTEXT === "production"
}

if (_shouldSendEvents()) {
  ReactGA.initialize("UA-53073906-4")
}

function sendPageView() {
  if (_shouldSendEvents()) {
    ReactGA.pageview(window.location.pathname + window.location.search)
  }
}

function sendOutboundLinkClick(event: MouseEvent<HTMLAnchorElement>) {
  // this typecasting sucks - find another way of doing this
  if (_shouldSendEvents()) {
    const currentTarget = (event.currentTarget as any) as { href: string }
    ReactGA.event({
      category: "Outbound Link",
      action: "click",
      label: currentTarget.href,
      transport: "beacon",
    })
  }
}

export { sendPageView, sendOutboundLinkClick }
