/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import { RouteComponentProps } from "@reach/router"
import { throttle } from "lodash"
import { Component, ReactNode } from "react"
import { Helmet } from "react-helmet"
import Spinner from "react-svg-spinner"
import { IThumbnails } from "../fetchSites"
import pageStyles from "../styles/pageStyles"
import SiteGrid from "./SiteGrid"

interface IProps extends RouteComponentProps {
  dataTitle: string
  title: string | ReactNode
  description?: string | ReactNode
  sitesData: {
    urls: string[]
    thumbnails: IThumbnails
  }[]
  loading: boolean
  allLoaded: boolean
  noIndex?: boolean
  handlePageLoad: () => void
  loadNextPage: () => void
}

const SPINNER_HEIGHT = 200

export default class Page extends Component<IProps> {
  componentDidMount() {
    this.props.handlePageLoad()
    window.addEventListener("resize", this.handleResize)
    window.addEventListener("scroll", this.handleScroll)
  }

  componentDidUpdate() {
    this.loadMoreIfNeeded()
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize)
    window.removeEventListener("scroll", this.handleScroll)
  }

  loadMoreIfNeeded() {
    if (
      this.props.sitesData.length > 0 &&
      document.body.getBoundingClientRect().height - SPINNER_HEIGHT < window.innerHeight
    ) {
      this.props.loadNextPage()
    }
  }

  handleResize = throttle(() => {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    })
  }, 250)

  handleScroll = throttle(() => {
    const scrollHeight = window.innerHeight
    const insideHeight = document.body.getBoundingClientRect().height
    const scrollTop = window.scrollY
    const scrollBottom = insideHeight - scrollTop - scrollHeight
    const TRIGGER_HEIGHT = scrollHeight
    if (scrollBottom < TRIGGER_HEIGHT) {
      this.props.loadNextPage()
    }
  }, 250)

  render() {
    const { title, description, sitesData, allLoaded, dataTitle, noIndex } = this.props
    return (
      <div css={pageStyles.page}>
        <Helmet>{noIndex && <meta name="robots" content="noindex" />}</Helmet>
        <h1 css={pageStyles.title}>{title}</h1>
        {description && <h2 css={pageStyles.subtitle}>{description}</h2>}
        <SiteGrid tag={dataTitle} sitesData={sitesData} />
        {!allLoaded && (
          <div
            css={css`
              height: ${SPINNER_HEIGHT}px;
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 50px;
            `}
          >
            {!allLoaded && <Spinner color={"rgba(255,255,255,.3)"} />}
          </div>
        )}
      </div>
    )
  }
}
