/** @jsx jsx */
import { jsx } from "@emotion/core"

const lifestyleWhite = (
  <svg viewBox="0 0 32 32">
    <path
      fill="#fff"
      d="M11.7 9.1zm-2.3.3zm-.3 2.3zm2.6-2.6zm.2-6.3zm0 26.4zm-2.8-8.9zM22.6 9.4zm.3 2.3zm-2.2-.4zm2.2.4zm-11.6-.4zm8.8-8.5zm-8.4 20.1zm8.4 6.3zm.2-6.3zm-.2 6.3zm-8.4-6.3zm8.6 0zM31.9 16c0 4.2-1.7 8.2-4.7 11.2s-7 4.7-11.2 4.7c-4.2 0-8.2-1.7-11.2-4.7S.1 20.2.1 16c0-4.2 1.7-8.2 4.7-11.2S11.8.1 16 .1c4.2 0 8.2 1.7 11.2 4.7s4.7 7 4.7 11.2zm-9.1 4.3c.1.1.1.1 0 0 .1.1.1.1 0 0 .1.1.1.1 0 0 .1.1.1.1 0 0l.1.1c2.1-.5 3.9-1.2 5.1-1.9 1.2-.8 1.9-1.6 1.9-2.5 0-.9-.7-1.7-1.9-2.5s-3-1.4-5.1-1.9h-.1v.1c.2 1.4.2 2.8.2 4.3.1 1.5 0 2.9-.2 4.3zM20.1 2.8c1.1 1.6 1.9 3.9 2.4 6.6 0 0 0 .1.1.1 2.7.5 5 1.4 6.6 2.4-.7-2.1-1.8-4.1-3.5-5.7A14.7 14.7 0 0 0 20 2.7h-.1c.1 0 .1.1.2.1zm.7 8.5c0-.1 0-.1 0 0-1.5-.3-3.1-.4-4.8-.4-1.7 0-3.3.1-4.7.3 0 0-.1 0-.1.1-.2 1.5-.3 3-.3 4.7 0 1.7.1 3.3.3 4.7 0 0 0 .1.1.1 1.5.2 3 .3 4.7.3 1.7 0 3.3-.1 4.7-.3 0 0 .1 0 .1-.1.2-1.5.3-3 .3-4.7.1-1.7-.1-3.3-.3-4.7zM13.5 4c-.8 1.2-1.4 3-1.9 5.1v.1h.1a30.03 30.03 0 0 1 8.6 0h.1v-.1C19.9 7 19.2 5.2 18.5 4c-.8-1.2-1.6-2-2.5-2-.9 0-1.7.8-2.5 2zM2.7 11.8v.1h.1c1.6-1.1 3.9-1.9 6.6-2.4 0 0 .1 0 .1-.1.5-2.7 1.4-5 2.4-6.6v-.1h-.1c-2.1.6-4.1 1.8-5.7 3.4s-2.8 3.6-3.4 5.7zM2 16c0 .9.7 1.7 1.9 2.5s3 1.4 5.1 1.9h.1v-.1c-.1-1.4-.2-2.8-.2-4.3s.1-2.9.3-4.3v-.1h-.1c-2.1.5-3.9 1.2-5.1 1.9-1.2.8-2 1.6-2 2.5zm9.9 13.2c-1.1-1.6-1.9-3.9-2.4-6.6 0 0 0-.1-.1-.1-2.7-.5-5-1.4-6.6-2.4.7 2.1 1.8 4.1 3.5 5.7 1.6 1.6 3.6 2.8 5.7 3.5h.1c-.1 0-.1-.1-.2-.1zm6.6-1.2c.8-1.2 1.4-3 1.9-5.1v-.1h-.1c-1.4.2-2.8.2-4.3.2s-2.9-.1-4.3-.2h-.1v.1c.5 2.1 1.2 3.9 1.9 5.1.8 1.2 1.6 1.9 2.5 1.9.9.1 1.7-.7 2.5-1.9zm10.8-7.8v-.1h-.1c-1.6 1.1-3.9 1.9-6.6 2.4 0 0-.1 0-.1.1-.5 2.7-1.4 5-2.4 6.6v.1h.1c2.1-.7 4.1-1.8 5.7-3.5 1.6-1.5 2.8-3.5 3.4-5.6z"
    />
  </svg>
)

export default lifestyleWhite
