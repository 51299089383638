/** @jsx jsx */
import { jsx } from "@emotion/core"

const photoWhite = (
  <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.18448 4.54276C9.34804 4.54276 9.49512 4.44318 9.55587 4.29132L10.8358 1.09137C11.0029 0.673748 11.4074 0.399902 11.8572 0.399902H21.5238C21.9736 0.399902 22.3781 0.673748 22.5452 1.09137L23.8251 4.29132C23.8859 4.44318 24.033 4.54276 24.1965 4.54276H29.1191C30.1433 4.54276 31.1256 4.94964 31.8499 5.67388C32.5741 6.39813 32.981 7.38042 32.981 8.40466V27.738C32.981 28.7622 32.5741 29.7445 31.8499 30.4688C31.1256 31.193 30.1433 31.5999 29.1191 31.5999H4.26193C3.23769 31.5999 2.2554 31.193 1.53115 30.4688C0.806903 29.7445 0.400024 28.7622 0.400024 27.738V8.40466C0.400024 7.38042 0.806903 6.39813 1.53115 5.67388C2.2554 4.94964 3.23769 4.54276 4.26193 4.54276H9.18448ZM12.8727 2.5999C12.7092 2.5999 12.5621 2.69948 12.5013 2.85135L11.2214 6.05129C11.0543 6.46891 10.6498 6.74276 10.2 6.74276H4.26193C3.82116 6.74276 3.39845 6.91785 3.08679 7.22952C2.77512 7.54119 2.60002 7.9639 2.60002 8.40466V27.738C2.60002 28.1788 2.77512 28.6015 3.08678 28.9131C3.39845 29.2248 3.82116 29.3999 4.26193 29.3999H29.1191C29.5598 29.3999 29.9825 29.2248 30.2942 28.9131C30.6059 28.6015 30.781 28.1788 30.781 27.738V8.40466C30.781 7.9639 30.6059 7.54119 30.2942 7.22952C29.9826 6.91785 29.5598 6.74276 29.1191 6.74276H23.181C22.7312 6.74276 22.3267 6.46891 22.1597 6.05129L20.8797 2.85135C20.8189 2.69948 20.6719 2.5999 20.5083 2.5999H12.8727ZM16.6905 12.2664C14.2473 12.2664 12.2667 14.247 12.2667 16.6902C12.2667 19.1334 14.2473 21.114 16.6905 21.114C19.1337 21.114 21.1143 19.1334 21.1143 16.6902C21.1143 14.247 19.1337 12.2664 16.6905 12.2664ZM10.0667 16.6902C10.0667 13.032 13.0322 10.0664 16.6905 10.0664C20.3487 10.0664 23.3143 13.032 23.3143 16.6902C23.3143 20.3484 20.3487 23.314 16.6905 23.314C13.0322 23.314 10.0667 20.3484 10.0667 16.6902Z"
      fill="white"
    />
  </svg>
)

export default photoWhite
