/** @jsx jsx */
import { jsx } from "@emotion/core"

const commerce = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18.4523 13.177C18.0526 12.43 16.9063 12.0825 15.9688 12.0651C15.0132 12.0651 14.1439 12.3257 13.727 12.7427C13.5707 12.9164 13.5016 13.1075 13.5016 13.3507C13.5016 13.8371 13.9359 14.1672 16.125 14.5494C18.1743 14.8969 21.2492 15.4354 21.2492 18.5624C21.2319 20.8035 19.3388 22.0369 17.2714 22.367V23.2182C17.2714 23.6526 16.9235 24 16.4893 24H15.4819C15.0477 24 14.6998 23.6526 14.6998 23.2182V22.3844C12.8067 22.1238 11.2952 21.1509 10.6003 19.7264C10.4268 19.3442 10.5658 18.8751 10.9474 18.684L11.903 18.1976C12.0946 18.0934 12.3199 18.076 12.5115 18.1455C12.7023 18.215 12.8758 18.354 12.963 18.5451C13.4317 19.5005 14.8742 19.848 15.9688 19.848C16.5938 19.848 18.574 19.7438 18.574 18.5277C18.5913 17.7633 17.6357 17.4506 15.7426 17.1031C13.8314 16.7904 10.9474 16.304 10.8783 13.3854C10.8265 11.0749 12.7722 9.96308 14.7179 9.63301V8.78176C14.7179 8.34745 15.065 8 15.4992 8H16.5238C16.9589 8 17.3059 8.34745 17.3059 8.78176V9.63301C18.8865 9.91097 20.1028 10.7275 20.7459 11.9435C20.9539 12.3257 20.815 12.7948 20.4334 13.0033L19.5123 13.4897C19.1299 13.6982 18.6612 13.5592 18.4523 13.177Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C7.16365 0 0 7.16345 0 16C0 24.8366 7.16365 32 16 32C24.8363 32 32 24.8366 32 16C32 7.16345 24.8363 0 16 0ZM2.52632 16C2.52632 8.55869 8.55839 2.52632 16 2.52632C23.4416 2.52632 29.4737 8.55869 29.4737 16C29.4737 23.4413 23.4416 29.4737 16 29.4737C8.55839 29.4737 2.52632 23.4413 2.52632 16Z"
      fill="white"
    />
  </svg>
)

export default commerce
