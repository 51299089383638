/** @jsx jsx */
import { jsx } from "@emotion/core"

const artWhite = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.658 25.8272L18.6589 25.8281C19.2517 26.4917 19.5773 27.3444 19.5773 28.2229C19.5773 30.1964 17.9736 31.8002 16 31.8002C7.28779 31.8002 0.200012 24.7137 0.200012 16.0002C0.200012 7.28799 7.2878 0.200195 16 0.200195C24.7343 0.200195 31.8 6.57697 31.8 14.3709C31.8 19.4884 27.6362 23.6522 22.5187 23.6522H19.6347C18.9121 23.6522 18.324 24.2406 18.324 24.9642C18.324 25.2742 18.4475 25.5886 18.658 25.8272ZM19.6347 21.3855H22.5187C26.3865 21.3855 29.5333 18.2386 29.5333 14.3709C29.5333 7.78338 23.4372 2.46686 16 2.46686C8.53889 2.46686 2.46668 8.53907 2.46668 16.0002C2.46668 23.4627 8.5389 29.5335 16 29.5335C16.7225 29.5335 17.3107 28.9453 17.3107 28.2229C17.3107 27.8076 17.1192 27.5062 16.9627 27.3312C16.3774 26.6684 16.0573 25.8289 16.0573 24.9642C16.0573 22.9906 17.6612 21.3855 19.6347 21.3855ZM6.86668 14.0002C6.86668 13.004 7.67047 12.2002 8.66668 12.2002C9.66289 12.2002 10.4667 13.004 10.4667 14.0002C10.4667 14.9964 9.66289 15.8002 8.66668 15.8002C7.67047 15.8002 6.86668 14.9964 6.86668 14.0002ZM10.8667 8.66686C10.8667 7.67065 11.6705 6.86686 12.6667 6.86686C13.6629 6.86686 14.4667 7.67065 14.4667 8.66686C14.4667 9.66307 13.6629 10.4669 12.6667 10.4669C11.6705 10.4669 10.8667 9.66307 10.8667 8.66686ZM17.5333 8.66686C17.5333 7.67065 18.3371 6.86686 19.3333 6.86686C20.3296 6.86686 21.1333 7.67065 21.1333 8.66686C21.1333 9.66307 20.3296 10.4669 19.3333 10.4669C18.3371 10.4669 17.5333 9.66307 17.5333 8.66686ZM21.5333 14.0002C21.5333 13.004 22.3371 12.2002 23.3333 12.2002C24.3296 12.2002 25.1333 13.004 25.1333 14.0002C25.1333 14.9964 24.3296 15.8002 23.3333 15.8002C22.3371 15.8002 21.5333 14.9964 21.5333 14.0002Z"
      fill="white"
    />
  </svg>
)

export default artWhite
