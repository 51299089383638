/** @jsx jsx */
import { jsx } from "@emotion/core"

const art = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5187 21.1853H19.6347C17.5507 21.1853 15.8573 22.88 15.8573 24.964C15.8573 25.8787 16.196 26.7653 16.8133 27.464C16.9493 27.616 17.1107 27.872 17.1107 28.2227C17.1107 28.8347 16.612 29.3333 16 29.3333C8.64933 29.3333 2.66667 23.352 2.66667 16C2.66667 8.64933 8.64933 2.66667 16 2.66667C23.352 2.66667 29.3333 7.91733 29.3333 14.3707C29.3333 18.128 26.276 21.1853 22.5187 21.1853ZM16 0C7.17733 0 0 7.17733 0 16C0 24.824 7.17733 32 16 32C18.084 32 19.7773 30.3067 19.7773 28.2227C19.7773 27.2947 19.4333 26.3947 18.808 25.6947C18.628 25.4907 18.524 25.2227 18.524 24.964C18.524 24.3507 19.0227 23.852 19.6347 23.852H22.5187C27.7467 23.852 32 19.5987 32 14.3707C32 6.44667 24.824 0 16 0ZM8.66667 12C7.56 12 6.66667 12.8933 6.66667 14C6.66667 15.1067 7.56 16 8.66667 16C9.77333 16 10.6667 15.1067 10.6667 14C10.6667 12.8933 9.77333 12 8.66667 12ZM10.6667 8.66667C10.6667 7.56 11.56 6.66667 12.6667 6.66667C13.7733 6.66667 14.6667 7.56 14.6667 8.66667C14.6667 9.77333 13.7733 10.6667 12.6667 10.6667C11.56 10.6667 10.6667 9.77333 10.6667 8.66667ZM19.3333 6.66667C18.2267 6.66667 17.3333 7.56 17.3333 8.66667C17.3333 9.77333 18.2267 10.6667 19.3333 10.6667C20.44 10.6667 21.3333 9.77333 21.3333 8.66667C21.3333 7.56 20.44 6.66667 19.3333 6.66667ZM21.3333 14C21.3333 12.8933 22.2267 12 23.3333 12C24.44 12 25.3333 12.8933 25.3333 14C25.3333 15.1067 24.44 16 23.3333 16C22.2267 16 21.3333 15.1067 21.3333 14Z"
      fill="black"
    />
  </svg>
)

export default art
