/** @jsx jsx */
import { css, jsx } from "@emotion/core"
import ImgixClient from "imgix-core-js"
import { useState } from "react"
import { IThumbnails } from "../fetchSites"
import placeholderImage from "../images/explore-empty-image.png"
import siteCardStyles from "../styles/siteCardStyles"
import { sendOutboundLinkClick } from "../utilities/analytics"

interface IProps {
  data: {
    urls: string[]
    thumbnails: IThumbnails
  }
  tag: string
}

const SiteCard = (props: IProps) => {
  const ImgixSubDomain = new URL(props.data.thumbnails.fullsize || "")

  const imgixClient = new ImgixClient({
    domain: ImgixSubDomain.hostname,
    useHTTPS: true,
  })

  const [loaded, setLoaded] = useState(false)
  const [error, setError] = useState(false)

  const imageUrl = ImgixSubDomain.pathname

  const sharedParameters = {
    w: 300,
    h: 400,
    fit: "crop",
    crop: "top",
  }
  const blockImageURL = imgixClient.buildURL(imageUrl, sharedParameters)
  const blurredPlaceholder = imgixClient.buildURL(imageUrl, {
    ...sharedParameters,
    blur: 200,
    px: 20,
    q: 10,
    dpr: 1,
  })
  const drpOne = imgixClient.buildURL(imageUrl, {
    ...sharedParameters,
    q: 40,
    dpr: 1,
  })
  const drpTwo = imgixClient.buildURL(imageUrl, {
    ...sharedParameters,
    q: 40,
    dpr: 2,
  })
  const drpThree = imgixClient.buildURL(imageUrl, {
    ...sharedParameters,
    q: 20,
    dpr: 3,
  })
  const blockImageSrcSet = `${drpOne} 1x, ${drpTwo} 2x, ${drpThree} 3x`
  const siteUrl = props.data.urls[0]
  return (
    <div
      css={css`
        ${siteCardStyles.card}
        background-image: url(${blurredPlaceholder});
      `}
    >
      <a onClick={sendOutboundLinkClick} href={siteUrl}>
        {props.data.thumbnails && !error ? (
          <img
            alt={props.tag}
            className="lazyload"
            style={{
              opacity: loaded ? 1 : 0,
              transition: "opacity 500ms",
            }}
            data-sizes="auto"
            data-src={blockImageURL}
            data-srcset={blockImageSrcSet}
            onLoad={() => {
              setLoaded(true)
            }}
            onError={() => {
              setError(true)
            }}
          />
        ) : (
          <img key="placeholder" src={placeholderImage} alt="not found" />
        )}
      </a>
    </div>
  )
}

export default SiteCard
