/** @jsx jsx */
import { jsx } from "@emotion/core"

const funny = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16 32C12.8355 32 9.74207 31.0616 7.11088 29.3035C4.4797 27.5454 2.42894 25.0466 1.21793 22.1229C0.00693253 19.1993 -0.309921 15.9823 0.307443 12.8786C0.924806 9.77487 2.44866 6.92394 4.6863 4.6863C6.92394 2.44866 9.77487 0.924806 12.8786 0.307443C15.9823 -0.309921 19.1993 0.00693253 22.1229 1.21793C25.0466 2.42894 27.5454 4.4797 29.3035 7.11088C31.0616 9.74207 32 12.8355 32 16C32 20.2435 30.3143 24.3131 27.3137 27.3137C24.3131 30.3143 20.2435 32 16 32ZM16 2.28572C13.2876 2.28572 10.6361 3.09005 8.38076 4.59699C6.12545 6.10394 4.36766 8.24582 3.32966 10.7518C2.29166 13.2577 2.02007 16.0152 2.54924 18.6755C3.07841 21.3358 4.38456 23.7795 6.30254 25.6975C8.22052 27.6154 10.6642 28.9216 13.3245 29.4508C15.9848 29.9799 18.7423 29.7084 21.2482 28.6703C23.7542 27.6323 25.8961 25.8746 27.403 23.6193C28.91 21.3639 29.7143 18.7124 29.7143 16C29.7143 12.3628 28.2694 8.87447 25.6975 6.30254C23.1255 3.73061 19.6373 2.28572 16 2.28572V2.28572Z"
      fill="black"
    />
    <path
      d="M16 26.2855C14.1609 26.2857 12.3554 25.7928 10.7715 24.8582C9.18757 23.9235 7.88324 21.5812 6.99433 19.9712C6.89898 19.7969 6.85069 19.6008 6.85425 19.4022C6.8578 19.2036 6.91307 19.0093 7.0146 18.8386C7.11612 18.6678 7.26041 18.5265 7.43322 18.4285C7.60602 18.3305 7.80139 18.2792 8.00005 18.2798H24C24.1977 18.2812 24.3917 18.3339 24.5629 18.4327C24.7341 18.5315 24.8768 18.673 24.977 18.8435C25.0772 19.0139 25.1314 19.2074 25.1345 19.405C25.1375 19.6027 25.0892 19.7978 24.9943 19.9712C24.1064 21.5796 22.8038 23.9207 21.2221 24.8553C19.6404 25.7898 17.8372 26.2837 16 26.2855ZM10.2858 20.5655C10.7715 21.4998 11.9241 22.9458 12.9106 23.3633C13.8971 23.7808 14.9574 23.996 16.0286 23.996C17.0998 23.996 18.1601 23.7808 19.1466 23.3633C20.1331 22.9458 21.2221 21.4998 21.7715 20.5655H10.2858Z"
      fill="black"
    />
    <path
      d="M8.57144 14.8571C8.3263 14.8576 8.08748 14.7793 7.89023 14.6338C7.69299 14.4882 7.54778 14.2831 7.47605 14.0486C7.40432 13.8142 7.40986 13.563 7.49186 13.3319C7.57386 13.1009 7.72797 12.9024 7.93144 12.7656L9.94287 11.4285L7.94287 10.0914C7.70941 9.91576 7.55205 9.65742 7.50312 9.36941C7.4542 9.08141 7.51742 8.7856 7.67981 8.54275C7.84219 8.29991 8.0914 8.12847 8.37626 8.06365C8.66111 7.99883 8.95997 8.04555 9.21144 8.19422L12.64 10.4799C12.7956 10.5845 12.9231 10.7257 13.0112 10.8911C13.0993 11.0565 13.1454 11.2411 13.1454 11.4285C13.1454 11.6159 13.0993 11.8005 13.0112 11.9659C12.9231 12.1313 12.7956 12.2725 12.64 12.3771L9.21144 14.6628C9.02226 14.7899 8.79938 14.8576 8.57144 14.8571V14.8571Z"
      fill="black"
    />
    <path
      d="M23.4286 14.8571C23.2045 14.8553 22.986 14.7878 22.8 14.6628L19.3714 12.3771C19.2159 12.2725 19.0884 12.1313 19.0003 11.9659C18.9121 11.8005 18.866 11.6159 18.866 11.4285C18.866 11.2411 18.9121 11.0565 19.0003 10.8911C19.0884 10.7257 19.2159 10.5845 19.3714 10.4799L22.8 8.19422C23.0515 8.04555 23.3503 7.99883 23.6352 8.06365C23.9201 8.12847 24.1693 8.29991 24.3316 8.54275C24.494 8.7856 24.5573 9.08141 24.5083 9.36941C24.4594 9.65742 24.302 9.91576 24.0686 10.0914L22.0572 11.4285L24.0572 12.7656C24.2598 12.9018 24.4136 13.0993 24.4958 13.3292C24.5781 13.5591 24.5845 13.8094 24.5143 14.0432C24.444 14.277 24.3006 14.4822 24.1053 14.6287C23.9099 14.7752 23.6728 14.8552 23.4286 14.8571V14.8571Z"
      fill="black"
    />
  </svg>
)

export default funny
