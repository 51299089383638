/** @jsx jsx */
import { jsx } from "@emotion/core"
import { APP_STORE_BADGE_LINK } from "../constants"
import icons from "../icons/index"
import appleAppStoreButton from "../images/icons/apple-app-store.svg"
import navStyles from "../styles/navStyles"
import useBodyLock from "../utilities/useBodyLock"
import NavCard from "./NavCard"
const { REACT_APP_PUBLIC_URL_ROOT } = process.env;

interface IProps {
  navIsOpen: boolean
  tags: any[]
}

const Nav = (props: IProps) => {
  const { navIsOpen, tags } = props
  useBodyLock(navIsOpen, "scrollable-nav")
  return (
    <div css={navIsOpen ? navStyles.nav_open : navStyles.nav}>
      <div css={navStyles.navHeader}>
        <a css={navStyles.navTopLogo} href={REACT_APP_PUBLIC_URL_ROOT}>
          {icons.universe}
        </a>
      </div>
      <div id="scrollable-nav" css={navStyles.navScroll}>
        <NavCard path={"/"} displayTitle="Featured" tagDataTitle={"featured"} />
        {tags.map((tag) => {
          return <NavCard key={tag.title} displayTitle={tag.displayTitle} tagDataTitle={tag.title} />
        })}
        <div css={navStyles.bottomOfScroll}>
          <a href={APP_STORE_BADGE_LINK}>
            <img
              css={navStyles.appleAppStoreButtonMobile}
              src={appleAppStoreButton}
              alt="Apple - Download on the App Store"
            />
          </a>
        </div>
      </div>
      <div css={navStyles.navFooter}>
        <a css={navStyles.universeLogo} href={REACT_APP_PUBLIC_URL_ROOT}>
          {icons.universe}
        </a>
        <a href={APP_STORE_BADGE_LINK}>
          <img
            css={navStyles.appleAppStoreButtonDesktop}
            src={appleAppStoreButton}
            alt="Apple - Download on the App Store"
          />
        </a>
      </div>
    </div>
  )
}

export default Nav
