/** @jsx jsx */
import { jsx } from "@emotion/core"

const businessWhite = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
    <path
      fill="#fff"
      d="M15.1 14.9l.4.4-.4-.4zm-5.3 5.2l.4.4-.4-.4zm-1.5-1.6l-.4-.4.4.4zm7.3 7.3l-.4.4.4-.4zM2.2 11.1l.4.4-.4-.4zM.4 12.8l.4.4-.4-.4zM20.5 2.6l.2.2-.4-.4.2.2zM27.8 0v.6V0zm-8.1 17.5zM21 29.8l-.4-.4.4.4zm9.4-28.2c.7.7 1 1.6 1 2.6L31.2 7c-.1 1.6-.8 3.2-2 4.3l-7.4 7.4c-.1.1-.2.3-.2.4v7.6c0 1-.4 2-1.1 2.7l-1.7 1.7c-.2.2-.4.3-.6.3-.2 0-.4-.1-.6-.3l-4.7-4.7-7.3-7.3-4.7-4.7c-.3-.3-.3-.9 0-1.2l1.7-1.7c.7-.7 1.7-1.1 2.7-1.1h7.6c.2 0 .3-.1.4-.2l7.4-7.4c1.2-1.2 2.7-1.9 4.3-2l2.8-.2c1-.1 1.9.3 2.6 1zM6.6 16.9l3.7-3.7c.2-.2.2-.4.1-.7s-.3-.4-.6-.4H5.3c-.5 0-1.1.2-1.5.6l-.7.7c-.2.2-.2.6 0 .8l2.6 2.6c.3.3.7.3.9.1zm13.3 5.2c0-.2-.1-.5-.4-.6-.2-.1-.5 0-.7.1l-3.7 3.7c-.1.2-.1.4-.1.5 0 .2.1.3.2.4l2.6 2.6c.2.2.6.2.8 0l.7-.7c.4-.4.6-.9.6-1.5v-4.5zm.3-4.2zM29.6 4c0-.6-.3-1-.5-1.2-.1-.2-.5-.5-1.1-.4l-2.8.2c-1.2.1-2.4.6-3.2 1.5l-7.8 7.8-6.3 6.3c-.1.1-.2.3-.2.4 0 .2.1.3.2.4l1.6 1.6c.2.2.6.2.8 0l5.2-5.2c.3-.3.9-.3 1.2 0 .3.3.3.9 0 1.2l-5.2 5.2c-.1.1-.2.3-.2.4 0 .2.1.3.2.4l1.6 1.6c.2.2.6.2.8 0l6.3-6.3 7.8-7.8c.9-.9 1.4-2 1.5-3.2l.1-2.9zM13.5 23.7l.4.4-.4-.4zM24 5.9c-.2-.2-.4-.3-.6-.3-.2 0-.4.1-.6.3-.2.2-.3.4-.3.6s.1.5.3.6l2.1 2.1c.3.3.9.3 1.2 0 .2-.2.3-.4.3-.6 0-.2-.1-.5-.3-.6L24 5.9zM10 29.8v-.5.5zm-.3-2.2l-5.4.8c-.2 0-.4 0-.5-.2-.1-.1-.2-.3-.2-.5l.8-5.4c.1-.5-.3-.9-.7-1-.5-.1-.9.3-1 .7l-1 7.3c0 .3.1.5.2.7.2.2.4.3.6.3h.1l7.3-1c.5-.1.8-.5.7-1 0-.5-.4-.8-.9-.7zm-5.4.1l-.6-.1.6.1zm.6-5.3H5h-.1z"
    />
  </svg>
)

export default businessWhite
