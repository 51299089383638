/** @jsx jsx */
import { jsx } from "@emotion/core"

const random = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.0002 20.8674C13.3153 20.8674 11.1311 18.6813 11.1311 15.9938C11.1311 13.3063 13.3153 11.1201 16.0002 11.1201C18.6851 11.1201 20.8692 13.3063 20.8692 15.9938C20.8692 18.6813 18.6851 20.8674 16.0002 20.8674M16.0002 8.33496C11.7822 8.33496 8.34888 11.7702 8.34888 15.9936C8.34888 20.2169 11.7822 23.6522 16.0002 23.6522C20.2182 23.6522 23.6516 20.2169 23.6516 15.9936C23.6516 11.7702 20.2182 8.33496 16.0002 8.33496"
      fill="black"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.1387 17.4207C28.7589 20.934 27.0325 24.0879 24.2822 26.3019C18.6049 30.8734 10.2663 29.9669 5.70056 24.2842C3.49002 21.5299 2.48004 18.0794 2.86122 14.5662C3.11858 12.1962 4.00057 9.99606 5.38477 8.13154C6.63681 8.64814 8.11978 8.50193 9.2494 7.59126C10.3832 6.67918 10.8437 5.25747 10.603 3.91791C12.3197 3.14926 14.156 2.77051 15.9868 2.77051C19.8555 2.77051 23.6924 4.45819 26.2994 7.70405V7.70265C28.5113 10.457 29.5199 13.9089 29.1387 17.4207M28.4682 5.95933V5.95794C23.7397 0.0761224 15.6807 -1.56282 9.14509 1.5424C7.7762 0.510575 5.83832 0.484118 4.4416 1.60784C3.04627 2.73157 2.65675 4.62812 3.36763 6.18769C1.56609 8.50477 0.419777 11.2716 0.0956383 14.2654C-0.366225 18.5181 0.855208 22.6955 3.53318 26.0291C6.6897 29.9572 11.332 32 16.016 32C19.5356 32 23.0775 30.847 26.0267 28.4729C29.3558 25.7923 31.4439 21.9742 31.9044 17.7216C32.3662 13.4689 31.1448 9.29152 28.4682 5.95933"
      fill="black"
    />
  </svg>
)

export default random
