/** @jsx jsx */
import { jsx } from "@emotion/core"

const funnyWhite = (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.95421 19.4043C6.95097 19.5855 6.99498 19.7644 7.08191 19.9233C7.5266 20.7288 8.07246 21.7122 8.70407 22.6108C9.33676 23.5109 10.0489 24.316 10.8223 24.7724C12.3908 25.6979 14.1787 26.186 15.9999 26.1858C17.8192 26.184 19.6049 25.695 21.1712 24.7695C21.9435 24.3132 22.6546 23.5084 23.2865 22.6088C23.9173 21.7107 24.4625 20.7279 24.9068 19.9232C24.9932 19.7651 25.0372 19.5872 25.0345 19.4069C25.0317 19.2266 24.9822 19.05 24.8908 18.8945C24.7993 18.739 24.6691 18.6098 24.5129 18.5197C24.3568 18.4296 24.18 18.3815 23.9998 18.3801H7.99975C7.81847 18.3796 7.6402 18.4264 7.48252 18.5158C7.32483 18.6052 7.19317 18.7342 7.10052 18.89C7.00788 19.0458 6.95745 19.2231 6.95421 19.4043ZM21.9463 20.4658H10.121L10.197 20.612C10.4442 21.0874 10.8587 21.6892 11.3357 22.225C11.8109 22.7589 12.3577 23.2383 12.8716 23.4558C13.8704 23.8785 14.944 24.0963 16.0286 24.0963C17.1132 24.0963 18.1867 23.8785 19.1856 23.4558C19.7003 23.2379 20.2314 22.7578 20.6982 22.2244C21.1671 21.6886 21.5802 21.0883 21.8576 20.6165L21.9463 20.4658Z"
      fill="white"
    />
    <path
      d="M10.1231 11.4288L7.98719 12.8487C7.80162 12.9735 7.66087 13.1547 7.58607 13.3655C7.51125 13.5763 7.50619 13.8056 7.57165 14.0195C7.6371 14.2334 7.76961 14.4206 7.94959 14.5534C8.12957 14.6862 8.34795 14.7577 8.57164 14.7572C8.77963 14.7576 8.98332 14.6957 9.15595 14.5797L12.5844 12.2941C12.7263 12.1987 12.8425 12.0699 12.9229 11.919C13.0033 11.768 13.0454 11.5996 13.0454 11.4286C13.0454 11.2576 13.0033 11.0892 12.9229 10.9382C12.8425 10.7873 12.7261 10.6584 12.5842 10.563L9.15824 8.27905C8.92925 8.14446 8.65748 8.1023 8.39842 8.16125C8.13849 8.2204 7.91108 8.37684 7.76291 8.59844C7.61473 8.82003 7.55704 9.08996 7.60169 9.35276C7.64618 9.61468 7.78895 9.84971 8.00079 10.0099L10.1231 11.4288Z"
      fill="white"
    />
    <path
      d="M23.4286 14.7572C23.2244 14.7554 23.0252 14.6938 22.8558 14.5799L19.4271 12.2941C19.2852 12.1987 19.1689 12.0699 19.0885 11.919C19.0081 11.768 18.966 11.5996 18.966 11.4286C18.966 11.2576 19.0081 11.0892 19.0885 10.9382C19.1689 10.7873 19.2853 10.6584 19.4272 10.563L22.8532 8.27905C23.0822 8.14446 23.3539 8.1023 23.613 8.16125C23.8729 8.2204 24.1003 8.37684 24.2485 8.59843C24.3967 8.82003 24.4544 9.08996 24.4097 9.35276C24.3652 9.61464 24.2225 9.84963 24.0107 10.0098L21.8769 11.4284L24.0015 12.8489C24.1864 12.9731 24.3266 13.1533 24.4016 13.363C24.4767 13.5728 24.4826 13.8011 24.4185 14.0145C24.3543 14.2279 24.2235 14.4151 24.0453 14.5488C23.8672 14.6823 23.6511 14.7553 23.4286 14.7572Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 31.9001C12.8553 31.9001 9.78115 30.9676 7.16641 29.2205C4.55167 27.4734 2.51373 24.9901 1.3103 22.0848C0.106865 19.1794 -0.208008 15.9825 0.405497 12.8982C1.019 9.81387 2.53333 6.98076 4.75698 4.75711C6.98064 2.53345 9.81375 1.01912 12.898 0.405619C15.9823 -0.207886 19.1793 0.106987 22.0846 1.31042C24.99 2.51385 27.4732 4.5518 29.2203 7.16654C30.9675 9.78128 31.9 12.8554 31.9 16.0001C31.9 20.217 30.2248 24.2613 27.243 27.2431C24.2611 30.2249 20.2169 31.9001 16 31.9001ZM16 2.18582C13.2678 2.18582 10.5969 2.99601 8.32518 4.51395C6.05343 6.03188 4.28282 8.18937 3.23725 10.7136C2.19168 13.2378 1.91811 16.0154 2.45113 18.6951C2.98416 21.3748 4.29984 23.8363 6.23181 25.7683C8.16377 27.7002 10.6252 29.0159 13.3049 29.5489C15.9847 30.082 18.7622 29.8084 21.2865 28.7628C23.8107 27.7173 25.9682 25.9467 27.4861 23.6749C29.0041 21.4032 29.8143 18.7323 29.8143 16.0001C29.8143 12.3363 28.3588 8.82261 25.7682 6.23193C23.1775 3.64125 19.6638 2.18582 16 2.18582Z"
      fill="white"
    />
  </svg>
)

export default funnyWhite
