/** @jsx jsx */
import { jsx } from "@emotion/core"

const business = (
  <svg viewBox="0 0 32 32">
    <path d="M.4 14.9l.2-.2-.2.2zm14.1-2.7l-.2-.2.2.2zm.6 2.7l.2.2-.2-.2zm-5.3 5.2l.2.2-.2-.2zm-6.2-6.3l-.2-.2.2.2zM2.2 11l.2.2-.2-.2zM.4 12.8l.2.2-.2-.2zM28 3v-.3.3zm3.5 4c-.1 1.7-.8 3.3-2.1 4.5L22 18.9v7.8a4 4 0 0 1-1.2 2.9L19 31.4c-.2.2-.5.3-.8.3-.3 0-.6-.1-.8-.3l-4.7-4.7-7.3-7.3-4.7-4.7c-.5-.5-.5-1.2 0-1.7l1.7-1.7c.8-.8 1.8-1.3 2.9-1.3H13l.1-.1 7.4-7.4C21.7 1.3 23.3.6 25 .4l2.8-.2c1.1-.1 2.1.3 2.8 1.1.7.7 1.1 1.8 1.1 2.8L31.5 7zM6.4 16.6l3.7-3.7.5-.5H5.3c-.5 0-.9.2-1.3.5l-.7.7-.2.2.3.2L6 16.6l.2.2.2-.2zm13.2 4.8l-.5.5-3.7 3.7-.2.2.2.2 2.6 2.6.2.2.2-.2.7-.7c.3-.3.5-.8.5-1.3V21.4zM29.3 4c0-.5-.2-.8-.4-1-.2-.2-.5-.4-1-.4l-2.8.2c-1.1.1-2.2.6-3 1.4L14.3 12 8 18.3l-.2.2.2.2 1.6 1.6.2.2.2-.2 5.2-5.2c.5-.5 1.2-.5 1.7 0s.5 1.2 0 1.7L11.7 22l-.2.2.2.2 1.6 1.6.2.2.2-.2 6.3-6.3 7.8-7.8c.8-.8 1.3-1.9 1.4-3l.1-2.9zm.4 7.7zm-9.7 6zm4.2-12c-.2-.2-.5-.3-.8-.3s-.6.1-.8.3c-.2.2-.3.5-.3.8s.1.6.3.8l2.1 2.1c.4.4 1.2.4 1.7 0 .2-.2.3-.5.3-.8 0-.3-.1-.6-.3-.8l-2.2-2.1zM9.7 27.3V27v.3l-5.4.7-.4.1.1-.4.8-5.4c.1-.6-.4-1.2-1-1.3-.6-.1-1.2.4-1.3 1l-1 7.3c-.1.4.1.7.3 1 .2.2.5.3.8.3h.2l7.3-1c.6-.1 1.1-.7 1-1.3-.2-.7-.8-1.1-1.4-1zm-5.4.4v.3-.3zm5.7 2.2v-.3.3z" />
  </svg>
)

export default business
