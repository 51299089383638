/** @jsx jsx */
import { jsx } from "@emotion/core"

const writingWhite = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34 32">
    <path
      fill="#fff"
      d="M25.3 8.1l.1.1-.1-.1zM15 26.6zM13.4 25h.2-.2zm8.9-.8l-.1-.1.1.1zM19 22.8v.2-.2zm12.8-8.4s-.1.1-.1.2V25.4c0 3.6-2.9 6.5-6.5 6.5H6.7C3.1 31.9.2 29 .2 25.4V6.7C.2 3.1 3.1.2 6.7.2h18.7c3.6 0 6.5 2.9 6.5 6.5V8.1c0 .1 0 .1.1.1.8.8 1.3 1.8 1.3 3-.1 1.2-.6 2.3-1.5 3.2zm-2.3 2.8c0-.1 0-.2-.1-.2h-.2l-7.1 7.1c-.4.4-1 .7-1.5.9L15 26.4h-.3c-.3 0-.6-.1-.8-.3-.3-.3-.4-.7-.3-1.1l1.4-5.6c.1-.6.5-1.1.9-1.6l9.6-9.6c1.1-1.1 2.5-1.5 3.9-1.4h.2l.1-.1c0-2.3-1.9-4.2-4.2-4.2H6.7c-2.3 0-4.2 1.9-4.2 4.2v18.7c0 2.3 1.9 4.2 4.2 4.2h18.7c2.3 0 4.2-1.9 4.2-4.2v-8.2zm-12.4 3.6c-.1 0-.1.1-.1.1l-.6 2.5c0 .1 0 .1.1.2 0 0 .1.1.2.1L19 23c.1 0 .1-.1.1-.1 0-.1 0-.1-.1-.2l-1.9-1.9c.1 0 0 0 0 0zm13.8-9.6c0-.6-.3-1.2-.7-1.6-.4-.3-.9-.5-1.4-.5-.6 0-1.3.2-1.7.7l-8.6 8.6-.1.1c0 .1 0 .1.1.1l2.8 2.8c.1.1.2.1.3 0l8.8-8.8c.3-.2.5-.8.5-1.4zM32 8l-.1.1.1-.1zM21.3 21.4l-.1.1.1-.1zM8 9.1h12c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1H8c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1zm6.5 5.6c0-.6-.5-1.1-1.1-1.1H8c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h5.3c.7 0 1.2-.5 1.2-1.1z"
    />
  </svg>
)

export default writingWhite
