export interface IThumbnails {
  fullsize: string | null
  large: string | null
  small: string | null
}

interface ISiteIn {
  urls: string
  thumbnails: IThumbnails
}

interface ISiteOut {
  urls: string
  thumbnails: IThumbnails | null
}

interface IMetadata {
  count: number
  offset: number
  total: number
}

interface IDataIn {
  results: ISiteIn[]
  metadata: IMetadata
}

interface IDataOut {
  results: ISiteOut[]
  metadata: IMetadata
}

const processData = (data: IDataIn): IDataOut => {
  return {
    metadata: data.metadata,
    results: data.results.map((site: ISiteIn) => {
      return {
        urls: site.urls,
        thumbnails: site.thumbnails,
      }
    }),
  }
}

const fetchSitesForTag = async (tag: string, offset: number, limit: number): Promise<IDataOut> => {
  const tagQuery = tag === "all" ? "" : `tag=${tag}&`
  const response = await fetch(
    `${process.env.REACT_APP_BUILDER_API}/web/explore?${tagQuery}limit=${limit}&offset=${offset}`
  )
  const data = await response.json()
  return processData(data)
}

export { fetchSitesForTag }
